<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="处理中心" name="first">
				<div class="padding16 ">
					<el-upload class="upload-demo" drag action="https://jsonplaceholder.typicode.com/posts/" multiple>
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>

					</el-upload>
					<div>文件名格式以官网报价总结为准，默认收件地址：四川省成都市武侯区魏玛国际广场，如需配送其它地址请在文件名上标注（收件人信息格式：【地址 姓名 手机号】）</div>
					<div>例如：名片-铜版卡纸250克-90X54-单面-200张-1款【河南省郑州市金水区郑州东站 张三 13839900000】</div>
					<el-tooltip class="item" effect="light" placement="top-start">
						<div slot="content" style="font-size: 14px;width:400px">1.为了您的订单能高效率高标准的生产印刷，请严格按照【下单注意事项】设计文件；
							<br />2.为确保能顺利上传文件，请使用谷歌、火狐或QQ、360浏览器的极速模式；
							<br />3.每个文件不超过500M；
							<br />4.我公司严格按照国家《印刷业管理条例》等法律法规以及相关规章制度的规定承接商务印刷业务，如发现有涉黄、代开发票、危害国家统一、主权和领土完整等违法印刷品，我司将会立即向公安机关举报，由公安机关依法追究其法律责任，并且印品直接销毁处理，货款不予返还，由此产生损失概不承担，谢谢配合！
							<br />5.当您上传的文件里带有 "R" 标，请您务必在下列【授权证书】上传商标注册证。
						</div>
						<el-button type="text">文件注意事项</el-button>
					</el-tooltip>
					<!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="传稿人电话：" prop="phone">
							<el-input v-model="ruleForm.phone" placeholder="为方便有问题快速核对，请留下传稿人电话"></el-input>
						</el-form-item>
						<el-form-item label="下单要求：" prop="ask">
							<el-input v-model="ruleForm.ask" placeholder="材料、尺寸、数量请务必备注清楚，若需代送或者发快递请备注地址（省市区街道路+收件人+电话），不备注默认是注册地址"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">提交文件</el-button>
						</el-form-item>
					</el-form> -->
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
export default {
	data: () => {
		return {
			activeName: 'first',
			ruleForm: {
				phone: '',
				ask: ''
			},
			rules: {
				phone: [
					{ required: true, message: '传稿人电话必须填写', trigger: 'blur' },
					{  max: 11, message: '长度11个数字内', trigger: 'blur' }
				],
				ask: [
					{ required: true, message: '材料、尺寸、数量请务必备注清楚', trigger: 'blur' }
				],
			}
		}
	},
	mounted() {
		console.log(this.$route, 'rrr');
	},
	methods: {
		handleClick() {

		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
	position: relative;
	font-size: 16px;
	padding: 0 24px !important;
}

::v-deep .el-tabs__content {
	padding: 8px;
	width: 100%;
	height: 100%;
}


::v-deep .el-upload {
	width: 100%;

}

::v-deep .el-upload-dragger {
	width: 100%;
	height: 360px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

::v-deep .el-upload-dragger .el-icon-upload {
	margin: 0;
}

.padding16 {
	padding: 16px;
	width: 100%;
	box-sizing: border-box;
}
</style>